@import url("https://fonts.googleapis.com/css2?family=Courier+Prime&family=Slabo+13px&display=swap");
body {
  font-family: "Courier Prime", monospace;
  background-color: #1d1d1d;
  background-image: url(img/hannah-busing-nME9TubZtSo-unsplash-30.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.container {
  text-align: center;
  background: rgb(29, 29, 29);
  background: linear-gradient(
    0deg,
    rgba(29, 29, 29, 0.9) 0%,
    rgba(29, 29, 29, 0.75) 30%,
    rgba(29, 29, 29, 0.25) 85%
  );
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1,
h2 {
  font-family: "Slabo 13px", serif;
  color: rgb(219, 219, 219);
}
a {
  font-family: "Courier Prime", monospace;
  color: rgb(219, 219, 219);
}
p {
  font-family: "Courier Prime", monospace;
  color: rgb(219, 219, 219);
  font-size: calc(5px + 2vmin);
}

header h1 {
  font-size: calc(30px + 2vmin);
  font-weight: 600;
  margin-bottom: 0;
}

header h2 {
  font-size: calc(10px + 2vmin);
  font-weight: 400;
  color: rgb(219, 219, 219);
  margin-top: 15px;
  margin-bottom: 0;
}

.contact {
  font-size: calc(10px + 2vmin);
}
.stik-logo {
  max-width: 500px;
}
footer {
  position: absolute;
  bottom: 0;
  padding: 0 10px;
  width: calc(100% - 20px);
  text-align: right;
}
footer p {
  margin: 0;
  font-size: 2vmin;
}
@media screen and (max-width: 600px) {
  .stik-logo {
    max-width: calc(100% - 40px);
    padding: 0 20px;
  }
}
